import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const About = () => (
  <Layout>
    <SEO
      title="Confidentiality Agreement"
      description="Your privacy is very crucial for us. Here are the fundamental principles to serve you better."
    />
    <div className="content-page">
      <div className="content container">
        <p>
          All intellectual and industrial property rights on the content,
          photos, designs, videos and their arrangement on the Flatart.agency
          website are owned by Flatart Digital Marketing Agency, unless
          expressly stated otherwise. The contents cannot be distributed without
          the permission of https://flatart.agency/ and cannot be shared without
          showing the source.
        </p>
        <p>
          Under no circumstances will flatart.agency be held responsible for the
          content and false information provided by the visitor, including, but
          not limited to, any errors or omissions in the content or any loss or
          damage caused by the use of the content on the website.
        </p>
        <p>
          Advertising and promotions about his or third party's services can be
          displayed on our flatart.agency website. Our website can be directed
          to other websites that do not belong to flatart.agency and which are
          not related to their activities. If the visitor visits any of these
          websites, flatart.agency is not responsible for the policies and
          practices of these websites that are used and owned by third parties.
        </p>
        <p>
          Any performance failure, error, deficiency, interruption, defect,
          delay in operation and / or transportation, computer virus and / or
          line and / or system failure that may occur during or after the use of
          this website, Flatart.com and / or its employees are not responsible,
          even if they are notified of the possibility of such damage and loss,
          including but not limited to losses and expenses. flatart.agency
          reserves the right to change all products and services, pages,
          information, visual elements, content on this website without prior
          notice.
        </p>
        <p>
          If you do not want to receive direct marketing messages sent to you by
          flatart.agency, you can send this request to us via the “leave list”
          extension under each message sent to you or you can contact us through
          our contact information.
        </p>
        <p>
          You can contact us by e-mail to get more information about the legal
          terms and privacy of our flatart.agency website and to report your
          opinions and criticisms about our site.
        </p>
        <h2>Visitor Information</h2>
        <p>
          The Analytics system, a product of Google, is used to keep the
          statistics of our blog. This system is a completely harmless system.
          However, in order to keep our visitor statistics, we would like to
          inform you which visitor came with which IP address and which city he
          received from our blog. We declare that this information is not used
          anywhere and will not be used.
        </p>
        <h2>Post Comments</h2>
        <p>
          The ability to comment on the content on our blog is open to every
          user. On the other hand, all responsibility for the comments belongs
          to the person making the comment. Comments are not published without
          moderation. We would like to point out that when insulting comments
          are written to the blogger or other people, when links with
          advertising content are given, these comments are deleted. In
          addition, the commenter will not be able to escape this responsibility
          regarding any published legal problem regarding the comments posted.
          Our blog visitors; It would be beneficial for them not to contain
          insulting, ad-content and third-party comments.
        </p>
        <h2>Cookies</h2>
        <p>
          Cookies or cookies using English are small text files saved on your
          computer or mobile device (such as smartphone, tablet) when you visit
          flatart.agency. It stores your information in cookies and helps us to
          provide you with a better experience when you visit the site. It helps
          us better identify you through products such as Google Analytics,
          which we use through cookies. In this way, we can keep accurate
          statistics and make special improvements for you.
        </p>
        <h2>Which Types Do We Use?</h2>
        <p>
          Two types of cookies, session cookies and persistent cookies, are used
          at flatart.agency. Session cookies are cookies that you use until you
          close the website from the browser, then they are deleted. Persistent
          cookies are cookies required for programs such as Google Analytics,
          which record your previous experiences. flatart.agency uses 2 tools
          that create cookies: Google Analytics and AddThis.com
        </p>
      </div>
    </div>
  </Layout>
)

export default About
